<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @change-page="handleChangePage"
      @search="handleSearch"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('pdf')"
            >PDF</vs-button
          >
        </div>
      </template>
      <template slot="thead">
        <vs-th>
          <vs-checkbox v-model="selectAll" @input="handleSelectAll" />
        </vs-th>
        <vs-th>Select</vs-th>
        <vs-th sort-key="code">POD Data</vs-th>
        <vs-th sort-key="code">Shipment Data</vs-th>
        <vs-th sort-key="name">Driver</vs-th>
        <vs-th sort-key="name">Customer Data</vs-th>
        <!-- <vs-th sort-key="name">SO Data</vs-th> -->
        <vs-th sort-key="name">DO/DN Data</vs-th>
        <!-- <vs-th sort-key="name">List Item</vs-th> -->
        <vs-th sort-key="name">Payment Term</vs-th>
        <vs-th sort-key="name">Source</vs-th>
        <vs-th sort-key="name">POD Status</vs-th>
        <vs-th sort-key="name">Document</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-input-group flex">
              <vs-checkbox
                v-model="selectedItems[indextr]"
                @input="(val) => handleSelectItems(val, tr)"
              />
            </div>
          </vs-td>
          <vs-td>
            <feather-icon
              icon="EyeIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              class="ml-4"
              @click="handleEdit(tr)"
            />
          </vs-td>
          <vs-td>
            <ol class="bullet-list">
              <li>
                <b>POD Code :</b>
                {{ tr.pod_code }}
              </li>
            </ol>
          </vs-td>

          <vs-td>
            <ol class="bullet-list">
              <li>
                {{ tr.shipment_number }}
              </li>
              <li>
                {{ tr.delivery_date }}
              </li>
            </ol>
          </vs-td>
          <vs-td>
            <ol class="bullet-list">
              <li>
                {{ tr.driver_name }}
              </li>
              <li>
                {{ tr.vehicle_number }}
              </li>
            </ol>
          </vs-td>
          <vs-td>
            <ol class="bullet-list">
              <li>
                {{ tr.customer_code }}
              </li>
              <li>
                {{ tr.customer_name }}
              </li>
              <li>
                {{ tr.zone_name }}
              </li>
            </ol>
          </vs-td>
          <!-- <vs-td>
            <ol>
              <li>
                {{ tr.sales_order_code }}
              </li>
            </ol>
          </vs-td> -->
          <vs-td>
            <ol class="bullet-list">
              <li>{{ tr.delivery_order_code }}</li>
              <li>{{ tr.customer_name }}</li>
            </ol>
          </vs-td>
          <!-- <vs-td>
            <ol v-for="(item, index) in tr.items_pod" :key="index">
              <li>{{ index + 1 }}. {{ item.item_name }}</li>
            </ol>
          </vs-td> -->
          <vs-td>
            {{ tr.payment_term }}
          </vs-td>
          <vs-td>
            {{ tr.notes }}
          </vs-td>
          <vs-td>
            {{ tr.status_delivery ? tr.status_delivery : "" }}
          </vs-td>
          <vs-td>
            <div class="vx-input-group flex">
              <vs-checkbox
                :vs-value="tr"
                color="success"
                disabled
                :checked="tr.is_document"
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
export default {
  components: {},
  props: {
    transportPlanID: {
      type: Number,
      default: null,
    },
    warehouseID: {
      type: Number,
      default: null,
    },
    warehouseName: {
      type: String,
      default: null,
    },
    driverName: {
      type: String,
      default: null,
    },
    dateStart: {
      type: String,
      default: "",
    },
    dateEnd: {
      type: String,
      default: "",
    },
    selectedStatus: {
      type: Object,
      default: null,
    },
    isFilters: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    // this.getWarehouse();
    // this.getShipmentNumber();
  },

  data() {
    return {
      table: this.tableDefaultState(),
      selectAll: false,
      selectedItems: this.$store.state.pod.pod.selected,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "updated_at",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      if (searching == "") {
        this.table.length = 10;
      }
      this.table.page = 1;
      this.getDatas();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getDatas();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getDatas();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getDatas();
    },

    getDatas() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/proof-of-delivery/pod-table", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: "updated_at",
            sort: this.table.sort,
            transport_plan_id:
              this.$store.getters["pod/pod/getTransportPlanID"],
            warehouse_id: this.$store.getters["pod/pod/getWarehouseID"],
            date_from: this.dateStart,
            date_to: this.dateEnd,
            driver_name: this.$store.getters["pod/pod/getDriverName"],
            status_delivery: this.selectedStatus
              ? this.selectedStatus.id
              : null,
            status: 4,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records != null) {
              this.table.data = [];
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;

              for (let index = 0; index < resp.data.records.length; index++) {
                this.table.data[index]["indextr"] = index;
                this.table.data[index]["source"] = "Manual";
              }
              this.$emit("isFilters", false);
              this.setStartEnd();
            } else {
              this.table.data = [];
              this.table.total = 0;
              this.table.totalPage = 0;
              this.table.totalSearch = 0;
              this.table.length = 0;
              this.table.data = [];
              this.setStartEnd();
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }

      if (this.table.total == 0) {
        valStart = 0;
      }

      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (this.totalSearch < this.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleEdit(data) {
      console.log("data", data);
      this.$vs.loading();
      this.$router.push({
        name: "proof-of-delivery-show",
        query: {
          id: data.id,
          action: "Show",
        },
      });
      this.$vs.loading.close();
    },
    handleSelectAll(val) {
      this.selectAll = val;
      if (val) {
        this.selectedItems = this.table.data.map((item) => true);
        this.$store.commit("pod/pod/setSelected", [...this.table.data]);
      } else {
        this.selectedItems = this.table.data.map((item) => false);
        this.$store.commit("pod/pod/setSelected", []);
      }
    },
    handleSelectItems(val, item) {
      const index = this.selectedItems.findIndex((i) => i.id === item.id);
      this.selectedItems.splice(index, 1, val);
      if (val) {
        this.$store.commit("pod/pod/addSelected", item);
      } else {
        this.$store.commit("pod/pod/removeSelected", item);
      }
      this.selectAll = this.selectedItems.every(Boolean);
    },
    handleExport() {
      console.log("download pdf");

      const width = 9.5 * 72; // 684 points
      const height = 11 * 72; // 792 points
      const doc = new jsPDF({
        orientation: "l",
        unit: "pt",
        format: [width, height],
      });
      let fontSize = 20;
      const lineHeightFactor = 300;

      // Set font to Courier for entire document
      doc.setFont("courier", "normal");
      const addHeader = (th, tm) => {
        doc.setFontSize(fontSize, "bold");
        doc.setFont("courier");
        doc.text("List Surat Jalan Logistik Ke Sales Admin", 400, 50, {
          align: "center",
        });
        doc.addImage(
          require("@/assets/images/logo/logo-sdn.png"),
          "PNG",
          20,
          20,
          100,
          50
        );

        doc.lineHeightProportion = 200;
      };

      addHeader();

      const headers = [
        [
          { content: "No.", styles: { halign: "center" } },
          { content: "DO No.", styles: { halign: "center" } },
          { content: "SJ Kembali", styles: { halign: "center" } },
          { content: "Ship to party", styles: { halign: "center" } },
          { content: "Description", styles: { halign: "center" } },
          { content: "GI Date", styles: { halign: "center" } },
          { content: "Tgl SJ Kembali", styles: { halign: "center" } },
        ],
      ];

      var data = this.$store.state.pod.pod.selected;

      var body = data.map((v, i) => {
        let docData = "";
        if (v.is_document) {
          docData = "V";
        } else {
          docData = "X";
        }
        return [
          i + 1,
          v.delivery_order_code,
          docData,
          v.customer_code,
          v.customer_name,
          "-",
          v.pod_date,
        ];
      });

      autoTable(doc, {
        startY: 100,
        head: headers,
        body: body,
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          theme: "grid",
          lineColor: [0, 0, 0],
          cellWidth: "auto",
          font: "courier",
          halign: "center",
          valign: "middle",
        },
        margin: { right: 10, left: 10 },
        theme: "grid",
        // tableLineWidth: 0.2,
        // tableLineColor: [0, 0, 0],
        styles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          // theme: "grid",
          // lineColor: [0, 0, 0],
          lineWidth: 0.5,
          halign: "center",
          valign: "middle",
          lineHeight: lineHeightFactor,
          font: "courier",
        },
        columnStyles: {
          0: { halign: "center", fontSize: "9", cellWidth: 30 },
          1: { halign: "center", fontSize: "9", cellWidth: 150 },
          2: { halign: "center", fontSize: "9", cellWidth: 80 },
          3: { halign: "center", fontSize: "9", cellWidth: 150 },
          4: { halign: "center", fontSize: "9", cellWidth: 150 },
          5: { halign: "center", fontSize: "9", cellWidth: 100 },
          6: { halign: "center", fontSize: "9", cellWidth: 100 },
        },
        didDrawCell: function (data) {
          if (data.section === "body") {
            const rowIndex = data.row.index;
            const cellX = data.cell.x;
            const cellY = data.cell.y;

            doc.setLineWidth(0.5);
            doc.setDrawColor(0, 0, 0);
            if (rowIndex === 0 && data.section === "body") {
              doc.line(cellX, cellY, cellX + data.cell.width, cellY);
            }
          }
        },
        didDrawPage: function (data) {
          addHeader();

          data.settings.margin.top = 100;
        },
        didParseCell: function (data) {
          if (data.section === "body") {
            data.cell.styles.lineWidth = 0.2;
          }
        },
      });

      // Footer
      const Warehouse = this.$store.getters["pod/pod/getWarehouseName"];
      const dateText = `${Warehouse}, ${moment().format("DD MMM YYYY")}`;

      // Footer Table Data including Date Text as a Row
      const footer = [
        [
          {
            content: dateText,
            colSpan: 6,
            styles: { halign: "left", fontStyle: "courier" },
          },
        ],
        ["Diserahkan Oleh,", "", "", "", "Diterima Oleh,", ""],
        ["", "", ""],
        ["", "", ""],
        ["Logistik", "", "", "", "Adm. Sales", ""],
      ];

      autoTable(doc, {
        head: [],
        body: footer,
        startY: doc.autoTable.previous.finalY + 20, // Position below main table
        margin: { left: 15 },
        theme: "plain",
        styles: {
          fontSize: 9,
          font: "courier",
          halign: "center",
          valign: "middle",
        },
        columnStyles: {
          0: { cellWidth: 150 },
          1: { cellWidth: 130 },
          2: { cellWidth: 100 },
          3: { cellWidth: 150 },
          4: { cellWidth: 130 },
        },
        pageBreak: "avoid", // Try to keep footer on the same page
      });

      const pageCount = doc.internal.getNumberOfPages();
      for (var j = 1; j <= pageCount; j++) {
        doc.setPage(j);
        doc.setFontSize(10);
        doc.text(
          "Halaman " + String(j) + " dari " + String(pageCount),
          720 - 20,
          680 - 25,
          null,
          null,
          "right"
        );
      }

      const pdfBlob = doc.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");
    },
  },
  watch: {
    "table.data": {
      handler(newData) {
        this.selectedItems = newData.map((item) =>
          this.$store.state.pod.pod.selected.some((i) => i.id === item.id)
        );
        this.selectAll = this.selectedItems.every(Boolean);
      },
      deep: true,
    },
    isFilters(val) {
      if (val) {
        this.getDatas();
        this.$emit("isFilters", false);
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>
<style>
.bullet-list {
  list-style-type: disc; /* Atur tipe tanda (dalam hal ini tanda titik) */
  padding-left: 20px; /* Atur jarak kiri untuk membuat tanda tampak lebih baik */
}
</style>
